import React from 'react';
import Icon from './Icon';

export default function LeftIcon(props) {
  return (
    <Icon {...props} iconfont>
      <path
        d='M723.675429 20.662857a36.571429 36.571429 0 0 1 54.564571 48.530286l-2.852571 3.181714L335.725714 512l439.588572 439.588571a36.571429 36.571429 0 0 1 2.889143 48.566858l-2.852572 3.181714a36.571429 36.571429 0 0 1-48.530286 2.852571l-3.181714-2.852571L258.194286 537.856a36.571429 36.571429 0 0 1-2.852572-48.530286l2.852572-3.181714L723.675429 20.662857z'
        opacity='.8'
      />
    </Icon>
  );
}
