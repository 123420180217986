import React from 'react';
import styled from 'styled-components';

import PageHeader from './components/PageHeader';
import PageFooter from './components/PageFooter';

import Routers from './routes';

export default function App() {
  return (
    <Wrapper>
      <PageHeader />
      <PageContent>
        <Routers />
      </PageContent>
      <PageFooter />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
`;

const PageContent = styled.div`
  width: 100%;
  height: max-content;
`;
