import React, { useEffect } from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import AppIntroBlock from './components/AppIntroBlock';

import { moreProductsInfoList } from './config';

export default function MoreApplications() {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  }, []);
  return (
    <Wrapper>
      {_.map(moreProductsInfoList, (item) => (
        <AppIntroBlock
          key={_.get(item, 'key')}
          projectBlockData={item}
          blockBgColor={_.get(item, 'key') % 2 === 0 ? '#ffffff' : '#FFFCF5'}
        />
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
