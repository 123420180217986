export const ourProductsInfoList = [
  {
    key: 0,
    appName: '来音练琴',
    modalTitleImg: 'laiyinlianqin_modal_title_img',
    appImg: 'laiyinlianqin_app_img',
    bgImg: 'laiyinlianqin_bg_img',
    adImgKey: 'laiyinlianqin_ad',
    iOSDownloadLink: 'https://apps.apple.com/cn/app/id1561951965',
    androidMarket: 'market://details?id=com.xihang.partnertrainingstudent',
    keywords: '来音，钢琴，小提琴，声乐，陪练，一对一，考级，乐理，练琴',
    bgImgInfo: {
      width: 720,
      height: 293,
      bottom: 53,
      right: -54,
    },
    introduction: [
      '来音练琴是一个安全靠谱的线上全乐器陪练教学平台，通过和老师1v1视频教学，保证孩子在家练琴的效率，同时解放家长的时间。',
    ],
    infos: [
      {
        title: '老师资质有保证',
        info: '专业团队严格审核老师资质，老师教学有保证。',
      },
      {
        title: '多种课程类型',
        info: '陪练课、主课、小班课、视频课程全都有。',
      },
      {
        title: '一节课一付',
        info: '支持学员上一节课付一节课的钱，不买课包更安全。',
      },
      {
        title: '自助灵活约课',
        info: '学员随时在App约课，没有销售烦你，省时省心。',
      },
      { title: '信息透明', info: '老师介绍、价格、学员评价清晰透明。' },
    ],
    infoTitleLength: 7,
    subIntroduction: ['觉得不错，就跟朋友推荐“来音练琴”平台吧，感谢您的支持~'],
    bundleApp: {
      name: '来音练琴老师端',
      detailPath: '/laiyinlianqinTeacher',
    },
  },
  {
    key: 1,
    appName: '来音智能陪练',
    modalTitleImg: 'laiyinzhinengpeilian_modal_title_img',
    appImg: 'laiyinzhinengpeilian_app_img',
    bgImg: 'laiyinzhinengpeilian_bg_img',
    adImgKey: 'laiyinzhinengpeilian_ad',
    iOSDownloadLink: 'https://apps.apple.com/cn/app/id1580896775',
    androidMarket: 'market://details?id=com.xihang.partnertrainingai',
    keywords: '来音，钢琴，小提琴，识谱，智能陪练，AI陪练，钢琴谱，免费',
    bgImgInfo: {
      width: 653,
      height: 716,
      bottom: -78,
      right: 6,
    },
    introduction: [
      '如果你在学钢琴/小提琴，来音智能陪练一定适合你。AI小音能帮你快速熟悉新曲子，识别流畅不卡顿，准确纠正错音错节奏，并给你的演奏打分，让你练琴更高效更有趣。',
      '来音智能陪练是你的练琴好帮手，不懂怎么练琴，小音会告诉你。',
    ],
    infos: [
      {
        title: '精准纠错',
        info: '精准识别错音错节奏等问题，实时纠错，帮你快速识谱。',
      },
      {
        title: '智能测评',
        info: '音准、节奏、速度综合评分。错误标注、回放、示范等帮你正确练习。',
      },
      {
        title: '示范视频',
        info: '演奏示范，高清视频播放，练习用正确的手型和指法演奏每一首曲子。',
      },
      {
        title: '海量乐谱',
        info: '教材、曲谱从入门到十级，找谱练琴用小音就够了。',
      },
      {
        title: '多模式练习',
        info: '新曲识谱用跟弹，没有错音后用自由练习，提升显而易见。',
      },
      {
        title: '乐理手册',
        info: '乐理题库专项练习、乐理词典知识速查，帮你高效掌握乐理。',
      },
    ],
    infoTitleLength: 5,
  },
  {
    key: 2,
    appName: '懂音律',
    modalTitleImg: 'dongyinlv_modal_title_img',
    appImg: 'dongyinlv_app_img',
    bgImg: 'dongyinlv_bg_img',
    adImgKey: 'dongyinlv_ad',
    iOSDownloadLink: 'https://apps.apple.com/cn/app/id1510270439',
    androidMarket: 'market://details?id=com.xihang.konwrhythm',
    keywords: '钢琴，吉他，乐器，自学，乐谱，五线谱，钢琴谱，吉他谱，曲谱',
    bgImgInfo: {
      width: 715,
      height: 675,
      bottom: -69,
      right: -31,
    },
    introduction: [
      '懂音律 专为学音乐的人打造的全乐器找谱练琴、学习交流平台。百万达人用户社区联动，优质曲谱上传分享，轻松找谱练习。',
    ],
    infos: [
      {
        title: '全乐器乐谱',
        info: '有钢琴,吉他,尤克里里,提琴,架子鼓,古筝,琵琶,二胡等中外管弦、打击乐器。',
      },
      {
        title: '智能陪练',
        info: 'AI乐谱可播放，听音陪练实时反馈练习时的错音，分段播放帮你纠正错误。',
      },
      {
        title: '弹奏教学',
        info: '专业老师优质教学视频与课件同步，让你边学边练快速提高。',
      },
      {
        title: '社区互动',
        info: '在线音乐学习、创作分享，评论互动与全球音乐人交流提升。',
      },
      {
        title: '功能丰富',
        info: '五线谱转简谱、内置节拍器、乐谱标注、弹奏录音、自动翻页、乐理知识等。',
      },
    ],
    infoTitleLength: 5,
  },
  {
    key: 3,
    appName: '来音钢琴',
    modalTitleImg: 'laiyingangqin_modal_title_img',
    appImg: 'laiyingangqin_app_img',
    bgImg: 'laiyingangqin_bg_img',
    adImgKey: 'laiyingangqin_ad',
    iOSDownloadLink: 'https://apps.apple.com/cn/app/id1523632955',
    androidMarket: 'market://details?id=com.xihang.piano',
    keywords: '钢琴，自学，零基础，钢琴键盘，模拟器，智能陪练，游戏',
    bgImgInfo: {
      width: 750,
      height: 479,
      bottom: 33,
      right: -100,
    },
    introduction: [
      '来音钢琴 专业有趣的学琴练琴APP，适合所有学琴用户。有零基础课程教学、AI智能陪练纠错、模拟器弹奏等功能。',
    ],
    infos: [
      {
        title: '钢琴教学',
        info: '多种课程教学内容，简单易懂，让你快速掌握基础钢琴知识。',
      },
      {
        title: '找谱练琴',
        info: 'AI智能陪练，精准纠正错音错节奏，让你练琴更高效更有趣。',
      },
      {
        title: '模拟器弹奏',
        info: '真实钢琴模拟器，掉音跟弹，随时随地弹奏钢琴名曲。',
      },
      {
        title: '钢琴连接',
        info: '支持MIDI连接、无线听音，配合各品牌钢琴使用，让钢琴有更多玩法 。',
      },
      {
        title: '曲库丰富',
        info: '海量古典流行歌曲，丰富的音乐种类任你选择。',
      },
    ],
    infoTitleLength: 5,
  },
  {
    key: 4,
    appName: '来音制谱',
    modalTitleImg: 'laiyinzhipu_modal_title_img',
    appImg: 'laiyinzhipu_app_img',
    bgImg: 'laiyinzhipu_bg_img',
    adImgKey: 'laiyinzhipu_ad',
    iOSDownloadLink: 'https://apps.apple.com/cn/app/id6450210894',
    androidMarket: 'market://details?id=com.xihang.editscore',
    bgImgInfo: {
      width: 713,
      height: 322,
      bottom: -49,
      right: -36,
    },
    introduction: ['来音制谱app是一款高效强大的乐谱编辑软件，虚拟钢琴键盘输入让你轻松打谱。'],
    infos: [
      {
        title: '可编辑',
        info: '专业而简单的五线谱编辑，让人人都可以随时记录或编辑乐谱。',
      },
      {
        title: '可分享',
        info: '支持导出MusicXML、pdf等文件格式，分享给好友或懂音律App等。',
      },
      {
        title: '可播放',
        info: '乐谱播放让你更快速欣赏自己的作品，并纠正错误使乐谱更完善。',
      },
      {
        title: '有教程',
        info: '详细的打谱视频教程，让你快速掌握打谱技能。',
      },
    ],
    infoTitleLength: 3,
  },
];

export const otherProductsInfoList = [
  {
    key: 0,
    appBgImg: 'website_laiyin_jiepaiqi_app_bg_img',
    appIcon: 'website_laiyin_jiepaiqi_app_icon',
    appName: '来音节拍器',
    iOSDownloadLink: 'https://apps.apple.com/cn/app/id1492418551',
    androidMarket: 'market://details?id=com.xihang.metronome',
    modalTitleImg: 'website_laiyin_laiyinjiepaiqi_modal_title_img',
    appIntr: ['顶级音乐家推荐的手机节拍器！', '比物理节拍器更好用！'],
    intrEmoji: '🎵🎶🎵',
  },
  {
    key: 1,
    appBgImg: 'website_laiyin_laiyintiaoyinqi_app_bg_img',
    appIcon: 'website_laiyin_laiyintiaoyinqi_app_icon',
    appName: '来音调音器',
    iOSDownloadLink: 'https://apps.apple.com/cn/app/id1493930943',
    androidMarket: 'market://details?id=com.xihang.tuner',
    modalTitleImg: 'website_laiyin_laiyintiaoyinqi_modal_title_img',
    appIntr: ['支持多种乐器精准调音', '让你的乐器不再走音'],
    intrEmoji: '🎶🎶🎶',
  },
  {
    key: 2,
    appBgImg: 'website_laiyin_yuelishouce_app_bg_img',
    appIcon: 'website_laiyin_yuelishouce_app_icon',
    appName: '乐理手册',
    iOSDownloadLink: 'https://apps.apple.com/cn/app/id1572795413',
    androidMarket: 'market://details?id=com.xihang.musictheory',
    modalTitleImg: 'website_laiyin_yuelishouce_modal_title_img',
    appIntr: ['音基考级知识大全', '你的音乐学习助手'],
    intrEmoji: '🎵🎵🎵',
  },
  {
    key: 3,
    appBgImg: 'website_laiyin_kaisangliansheng_app_bg_img',
    appIcon: 'website_laiyin_kaisangliansheng_app_icon',
    appName: '开嗓练声',
    iOSDownloadLink: 'https://apps.apple.com/cn/app/id1591389896',
    androidMarket: 'market://details?id=com.xihang.vocalmusic',
    modalTitleImg: 'website_laiyin_kaisangliansheng_modal_title_img',
    appIntr: ['自动音阶弹奏、专业音调仪，', '随时随地开嗓练声'],
    intrEmoji: '🎤🎤🎤',
  },
  {
    key: 4,
    appBgImg: 'website_laiyin_jita_app_bg_img',
    appIcon: 'website_laiyin_jita_app_icon',
    appName: '来音吉他',
    iOSDownloadLink: 'https://apps.apple.com/cn/app/id1577469860',
    androidMarket: 'market://details?id=com.xihang.guitar',
    modalTitleImg: 'website_laiyin_laiyinjita_modal_title_img',
    appIntr: ['零基础轻松学会吉他弹唱', '像专业吉他手一样'],
    intrEmoji: '🎸🎸🎸',
  },
  {
    key: 5,
    appBgImg: 'website_laiyin_jiazigu_app_bg_img',
    appIcon: 'website_laiyin_jiazigu_app_icon',
    appName: '来音架子鼓',
    iOSDownloadLink: 'https://apps.apple.com/cn/app/id1525856471',
    androidMarket: '',
    modalTitleImg: 'website_laiyin_laiyinjiazigu_modal_title_img',
    appIntr: ['这是一款很酷的应用', '让你体会到真实的打鼓乐趣'],
    intrEmoji: '🥁🥁🥁',
  },
  {
    key: 6,
    appBgImg: 'website_laiyin_xiaotiqin_app_bg_img',
    appIcon: 'website_laiyin_xiaotiqin_app_icon',
    appName: '来音小提琴',
    iOSDownloadLink: 'https://apps.apple.com/cn/app/id1628747999',
    androidMarket: 'market://details?id=com.xihang.violin',
    modalTitleImg: 'website_laiyin_laiyinxiaotiqin_modal_title_img',
    appIntr: ['专业智能陪练纠错，', '让你每次练习都有进步'],
    intrEmoji: '🎻🎻🎻',
  },
  {
    key: 7,
    appBgImg: 'website_laiyin_youkelili_app_bg_img',
    appIcon: 'website_laiyin_youkelili_app_icon',
    appName: '来音尤克里里',
    iOSDownloadLink: 'https://apps.apple.com/cn/app/id1632866396',
    androidMarket: 'market://details?id=com.xihang.ukulele',
    modalTitleImg: 'website_laiyin_laiyinukelili_modal_title_img',
    appIntr: ['口袋里的尤克里里弹唱神器'],
    intrEmoji: '🎶🎵🎶',
  },
  {
    key: 8,
    appBgImg: 'website_laiyin_dajidian_app_bg_img',
    appIcon: 'website_laiyin_dajidian_app_icon',
    appName: '打击垫',
    iOSDownloadLink: 'https://apps.apple.com/cn/app/id1537352309',
    androidMarket: '',
    modalTitleImg: 'website_laiyin_dajidian_modal_title_img',
    appIntr: ['有了电音打击垫', '你也可以做DJ'],
    intrEmoji: '⚡️🎧⚡️',
  },
  {
    key: 9,
    appBgImg: 'website_laiyin_tiaoyinqi_app_bg_img',
    appIcon: 'website_laiyin_tiaoyinqi_app_icon',
    appName: '调音器',
    iOSDownloadLink: 'https://apps.apple.com/cn/app/id1591009504',
    // androidMarket: 'market://details?id=com.xihang.tuner',
    androidMarket: '',
    modalTitleImg: 'website_laiyin_tiaoyinqi_modal_title_img',
    appIntr: ['支持多种乐器精准调音', '让你的乐器不再走音'],
    intrEmoji: '🎶🎶🎶',
  },

  {
    key: 10,
    appBgImg: 'website_laiyin_guzheng_app_bg_img',
    appIcon: 'website_laiyin_guzheng_app_icon',
    appName: '来音古筝',
    iOSDownloadLink: 'https://apps.apple.com/cn/app/id6447677054',
    androidMarket: 'market://details?id=com.xihang.guzheng',
    modalTitleImg: 'website_laiyin_guzheng_modal_title_img',
    appIntr: ['真实的古筝模拟器', '随心演奏喜欢的曲目'],
    intrEmoji: '🎵🎵🎵',
  },
  {
    key: 11,
    appBgImg: 'occupy_bitmap',
    appIcon: 'occupy_bitmap_icon',
    appName: '',
    // iOSDownloadLink: 'https://apps.apple.com/cn/app/id1591009504',
    // androidMarket: 'market://details?id=com.xihang.tuner',
    androidMarket: '',
    modalTitleImg: 'occupy_bitmap_icon',
    appIntr: ['', ''],
    intrEmoji: '',
  },
];

export const otherProductsInfoListOfPhone = [
  {
    key: 0,
    appBgImg: 'website_laiyin_jiepaiqi_app_bg_img',
    appIcon: 'website_laiyin_jiepaiqi_app_icon',
    appName: '来音节拍器',
    iOSDownloadLink: 'https://apps.apple.com/cn/app/id1492418551',
    androidMarket: 'market://details?id=com.xihang.metronome',
    modalTitleImg: 'website_laiyin_laiyinjiepaiqi_modal_title_img',
    appIntr: ['顶级音乐家推荐的手机节拍器！', '比物理节拍器更好用！'],
    intrEmoji: '🎵🎶🎵',
  },
  {
    key: 1,
    appBgImg: 'website_laiyin_laiyintiaoyinqi_app_bg_img',
    appIcon: 'website_laiyin_laiyintiaoyinqi_app_icon',
    appName: '来音调音器',
    iOSDownloadLink: 'https://apps.apple.com/cn/app/id1493930943',
    androidMarket: 'market://details?id=com.xihang.tuner',
    modalTitleImg: 'website_laiyin_laiyintiaoyinqi_modal_title_img',
    appIntr: ['支持多种乐器精准调音', '让你的乐器不再走音'],
    intrEmoji: '🎶🎶🎶',
  },
  {
    key: 2,
    appBgImg: 'website_laiyin_yuelishouce_app_bg_img',
    appIcon: 'website_laiyin_yuelishouce_app_icon',
    appName: '乐理手册',
    iOSDownloadLink: 'https://apps.apple.com/cn/app/id1572795413',
    androidMarket: 'market://details?id=com.xihang.musictheory',
    modalTitleImg: 'website_laiyin_yuelishouce_modal_title_img',
    appIntr: ['音基考级知识大全', '你的音乐学习助手'],
    intrEmoji: '🎵🎵🎵',
  },
  {
    key: 3,
    appBgImg: 'website_laiyin_kaisangliansheng_app_bg_img',
    appIcon: 'website_laiyin_kaisangliansheng_app_icon',
    appName: '开嗓练声',
    iOSDownloadLink: 'https://apps.apple.com/cn/app/id1591389896',
    androidMarket: 'market://details?id=com.xihang.vocalmusic',
    modalTitleImg: 'website_laiyin_kaisangliansheng_modal_title_img',
    appIntr: ['自动音阶弹奏、专业音调仪，', '随时随地开嗓练声'],
    intrEmoji: '🎤🎤🎤',
  },
  {
    key: 4,
    appBgImg: 'website_laiyin_jita_app_bg_img',
    appIcon: 'website_laiyin_jita_app_icon',
    appName: '来音吉他',
    iOSDownloadLink: 'https://apps.apple.com/cn/app/id1577469860',
    androidMarket: 'market://details?id=com.xihang.guitar',
    modalTitleImg: 'website_laiyin_laiyinjita_modal_title_img',
    appIntr: ['零基础轻松学会吉他弹唱', '像专业吉他手一样'],
    intrEmoji: '🎸🎸🎸',
  },
  {
    key: 5,
    appBgImg: 'website_laiyin_jiazigu_app_bg_img',
    appIcon: 'website_laiyin_jiazigu_app_icon',
    appName: '来音架子鼓',
    iOSDownloadLink: 'https://apps.apple.com/cn/app/id1525856471',
    androidMarket: '',
    modalTitleImg: 'website_laiyin_laiyinjiazigu_modal_title_img',
    appIntr: ['这是一款很酷的应用', '让你体会到真实的打鼓乐趣'],
    intrEmoji: '🥁🥁🥁',
  },
  {
    key: 6,
    appBgImg: 'website_laiyin_xiaotiqin_app_bg_img',
    appIcon: 'website_laiyin_xiaotiqin_app_icon',
    appName: '来音小提琴',
    iOSDownloadLink: 'https://apps.apple.com/cn/app/id1628747999',
    androidMarket: 'market://details?id=com.xihang.violin',
    modalTitleImg: 'website_laiyin_laiyinxiaotiqin_modal_title_img',
    appIntr: ['专业智能陪练纠错，', '让你每次练习都有进步'],
    intrEmoji: '🎻🎻🎻',
  },
  {
    key: 7,
    appBgImg: 'website_laiyin_youkelili_app_bg_img',
    appIcon: 'website_laiyin_youkelili_app_icon',
    appName: '来音尤克里里',
    iOSDownloadLink: 'https://apps.apple.com/cn/app/id1632866396',
    androidMarket: 'market://details?id=com.xihang.ukulele',
    modalTitleImg: 'website_laiyin_laiyinukelili_modal_title_img',
    appIntr: ['口袋里的尤克里里弹唱神器'],
    intrEmoji: '🎶🎵🎶',
  },
  {
    key: 8,
    appBgImg: 'website_laiyin_dajidian_app_bg_img',
    appIcon: 'website_laiyin_dajidian_app_icon',
    appName: '打击垫',
    iOSDownloadLink: 'https://apps.apple.com/cn/app/id1537352309',
    androidMarket: '',
    modalTitleImg: 'website_laiyin_dajidian_modal_title_img',
    appIntr: ['有了电音打击垫', '你也可以做DJ'],
    intrEmoji: '⚡️🎧⚡️',
  },
  {
    key: 9,
    appBgImg: 'website_laiyin_tiaoyinqi_app_bg_img',
    appIcon: 'website_laiyin_tiaoyinqi_app_icon',
    appName: '调音器',
    iOSDownloadLink: 'https://apps.apple.com/cn/app/id1591009504',
    // androidMarket: 'market://details?id=com.xihang.tuner',
    androidMarket: '',
    modalTitleImg: 'website_laiyin_tiaoyinqi_modal_title_img',
    appIntr: ['支持多种乐器精准调音', '让你的乐器不再走音'],
    intrEmoji: '🎶🎶🎶',
  },

  {
    key: 10,
    appBgImg: 'website_laiyin_guzheng_app_bg_img',
    appIcon: 'website_laiyin_guzheng_app_icon',
    appName: '来音古筝',
    iOSDownloadLink: 'https://apps.apple.com/cn/app/id6447677054',
    androidMarket: 'market://details?id=com.xihang.guzheng',
    modalTitleImg: 'website_laiyin_guzheng_modal_title_img',
    appIntr: ['真实的古筝模拟器', '随心演奏喜欢的曲目'],
    intrEmoji: '🎵🎵🎵',
  },
];
