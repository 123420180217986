import React, { useEffect, useRef } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';
import Clipboard from 'clipboard';
import { message } from 'antd';
import { useSearchParams } from 'react-router-dom';

import useIsPhone from '../../hooks/useIsPhone';
import HeaderImg from '../../components/HeaderImg';
import BlockTitleImage from '../../components/BlockTitleImage';

import { coreAdvantageList, coreTeamList } from './config';

export default function AboutUs() {
  const isPhone = useIsPhone();
  const joinUsRef = useRef();
  const [getSearchArr] = useSearchParams();

  const [messageApi, contextHolder] = message.useMessage();
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    if (getSearchArr.getAll('jumpTo')[0] === 'joinUs') {
      const myRef = joinUsRef;
      if (myRef.current) {
        window.scrollTo(0, myRef.current.offsetTop || 0);
      }
    }
  }, [getSearchArr]);

  const handleCopyEmail = () => {
    const clipboard = new Clipboard('.copy', {
      text: () => 'zhaopin@quthing.com',
    });
    clipboard.on('success', function () {
      messageApi.open({
        type: 'success',
        content: '合作简历投递邮箱已成功复制到剪切板，请及时发送邮件与我们联系',
        className: 'custom-class',
        style: {
          marginTop: '12vh',
        },
      });
      clipboard.destroy();
    });
  };
  return (
    <Wrapper>
      {contextHolder}
      <HeaderImg url={require(`./images/${isPhone ? 'phone' : 'pc'}_header.png`)} />
      <BlockWrapper>
        <BlockTitleImage url={require(`./images/companyIntro_img.png`)} />
        <BlockContentWrapper>
          <CompanyBlock>
            {isPhone && <CompanyIntroImg src={require('./images/companyIntro_logos_img.png')} />}
            <CompanyBlockInfos>
              <CompanyBlockInfoTitle>
                “来⾳”是北京夕航科技有限公司旗下品牌，是⼀个⾳乐教育科技品牌，通过“互联⽹+AI⼤数据”的⽅式，让学⾳乐更⾼效更有趣。
              </CompanyBlockInfoTitle>
              <CompanyBlockInfoContent>
                <div>
                  夕航成⽴于2018年，是⼀家国家⾼新技术企业，拥有数⼗项知识产权，获得过国内知名投资机构的投资。
                </div>
                <div>
                  公司有多位互联⽹产品技术专家，及多位⾳乐学院毕业的专业⾳乐⽣。与学校、乐器⼚商、版权机构等产业上下游机构陆续展开了合作，⼀同为⾳乐教育的发展做出贡献。
                </div>
              </CompanyBlockInfoContent>
            </CompanyBlockInfos>
            {!isPhone && <CompanyIntroImg src={require('./images/companyIntro_logos_img.png')} />}
          </CompanyBlock>
        </BlockContentWrapper>
      </BlockWrapper>
      <BlockWrapper bgColor='#FFFCF5'>
        <BlockTitleImage url={require(`./images/coreAdvantages_img.png`)} />
        <BlockContentWrapper>
          <CoreAdvantages>
            {_.map(coreAdvantageList, ({ key, imgName, title, info }) => {
              return (
                <CoreAdvantage key={key}>
                  <CoreAdvantageImg src={require(`./images/${imgName}.png`)} />
                  <CoreAdvantageContent>
                    <CoreAdvantageContentTitle>{title}</CoreAdvantageContentTitle>
                    <CoreAdvantageContentInfo>{info}</CoreAdvantageContentInfo>
                  </CoreAdvantageContent>
                </CoreAdvantage>
              );
            })}
          </CoreAdvantages>
        </BlockContentWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <BlockTitleImage url={require(`./images/coreTeam_img.png`)} />
        <BlockContentWrapper>
          <CoreTeam>
            {_.map(coreTeamList, ({ key, imgName, name, title, infos }) => {
              return (
                <CoreTeamItemWrapper key={key}>
                  <CoreTeamItemImg src={require(`./images/${imgName}.png`)} />
                  <CoreTeamItemContent>
                    <CoreTeamItemContentTitle>
                      <div className='name'>{name}</div>
                      <div className='title'>{title}</div>
                    </CoreTeamItemContentTitle>
                    <CoreTeamItemContentInfo>
                      {_.map(infos, (item) => (
                        <div key={item}>{item}</div>
                      ))}
                    </CoreTeamItemContentInfo>
                  </CoreTeamItemContent>
                </CoreTeamItemWrapper>
              );
            })}
          </CoreTeam>
        </BlockContentWrapper>
      </BlockWrapper>
      <BlockWrapper bgColor='#FFFCF5' ref={joinUsRef}>
        <BlockTitleImage url={require(`./images/joinUs_img.png`)} />
        <BlockContentWrapper>
          <JoinUsWrapper>
            <WorksCategoryList>
              <CategoryItem>技术类</CategoryItem>
              <CategoryItem>产品类</CategoryItem>
              <CategoryItem>设计类</CategoryItem>
              <CategoryItem>市场类</CategoryItem>
              <CategoryItem>职能类</CategoryItem>
            </WorksCategoryList>
            <Info>想加入我们？</Info>
            <Info>请将简历发送至下方邮箱！</Info>
            <ConcactUsBtn className='copy' onClick={handleCopyEmail}>
              zhaopin@quthing.com
            </ConcactUsBtn>
          </JoinUsWrapper>
        </BlockContentWrapper>
      </BlockWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BlockWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${(props) => props.bgColor || '#fff'};
`;

const BlockContentWrapper = styled.div`
  display: flex;
  width: 100%;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  ${breakpoint('desktop')`
    width: 1200px;
    height: max-content;
    justify-content: center;
  `}
`;

const CompanyBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 16px;
  margin: 24px 0 40px 0;
  ${breakpoint('desktop')`
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin: 50px 0 90px 0;
    padding-right: 10px;
  `}
`;

const CompanyBlockInfos = styled.div`
  ${breakpoint('desktop')`
    width: 600px;
  `}
`;

const CompanyBlockInfoTitle = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2c2e34;
  line-height: 26px;
  ${breakpoint('desktop')`
    font-size: 18px;
    line-height: 34px;
  `}
`;

const CompanyBlockInfoContent = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4b4f5a;
  line-height: 26px;
  margin-top: 9px;
  ${breakpoint('desktop')`
    font-size: 16px;
    line-height: 34px;
    margin-top: 20px;
  `}
`;

const CompanyIntroImg = styled.img`
  width: 100%;
  /* height: max-content; */
  object-fit: cover;
  border-radius: 14px;
  margin-bottom: 13px;
  ${breakpoint('desktop')`
    width: 550px;
    height: 350px;
    box-shadow: 0px 2px 12px 0px rgba(101, 43, 0, 0.08);
    margin-bottom: 0;
  `}
`;

const CoreAdvantages = styled.div`
  width: max-content;
  display: flex;
  margin: 24px 0 40px 0;
  padding-left: 16px;
  ${breakpoint('desktop')`
    padding-left: 0;
    margin: 50px 0 90px 0;
  `}
`;

const CoreAdvantage = styled.div`
  width: 285px;
  height: 341px;
  background: #ffffff;
  box-shadow: 0px 2px 12px 0px rgba(101, 43, 0, 0.08);
  border-radius: 14px;
  margin-right: 15px;
  padding: 16px;
  ${breakpoint('desktop')`
    &:last-child {
        margin-right: 0;
    }
  `}
`;

const CoreAdvantageImg = styled.img`
  object-fit: cover;
  width: 100%;
  height: auto;
`;

const CoreAdvantageContent = styled.div``;

const CoreAdvantageContentTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2c2e34;
  line-height: 22px;
  margin: 14px 0 8px 0;
`;

const CoreAdvantageContentInfo = styled.div`
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4b4f5a;
  line-height: 20px;
`;

const CoreTeam = styled.div`
  justify-content: center;
  width: max-content;
  display: flex;
  margin: 24px 0 40px 0;
  padding: 0 16px;
  ${breakpoint('desktop')`
    margin: 50px 0 90px 0;
    padding: 0;
  `}
`;

const CoreTeamItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  width: 298px;
  height: 414px;
  ${breakpoint('desktop')`
   width: 326px;
  `}
  background: #ffffff;
  box-shadow: 0px 2px 12px 0px rgba(101, 43, 0, 0.08), 0px 2px 12px 0px rgba(101, 43, 0, 0.08);
  border-radius: 14px;
  margin-right: 15px;
  &:last-child {
    margin-right: 0;
  }
`;

const CoreTeamItemImg = styled.img`
  width: 160px;
  height: 160px;
  border-radius: 50%;
`;

const CoreTeamItemContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CoreTeamItemContentTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 16px 0 8px 0;
  > .name {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #2c2e34;
    line-height: 25px;
  }
  > .title {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4b4f5a;
    line-height: 20px;
    margin-left: 4px;
  }
`;

const CoreTeamItemContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > div {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4b4f5a;
    line-height: 24px;
  }
`;

const JoinUsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 24px 0 40px 0;
  padding: 0 16px;
  width: 100%;
  ${breakpoint('desktop')`
    margin: 50px 0 90px 0;
    padding: 0;
  `}
`;

const WorksCategoryList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(3, 82px);
  grid-gap: 16px;
  width: 100%;
  margin-bottom: 40px;
  ${breakpoint('desktop')`
    display: flex;
    margin-bottom: 80px;
    grid-gap: 0;
  `}
`;

const CategoryItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fd6702;
  background: #fff2d6;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  border-radius: 10px;
  ${breakpoint('desktop')`
    width: 220px;
    height: 138px;
    border-radius: 14px;
    font-size: 24px;
    line-height: 22px;
    margin-right: 25px;
    &:last-child{
        margin-right: 0; 
    }
  `}
`;

const Info = styled.div`
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #2c2e34;
  line-height: 25px;
  ${breakpoint('desktop')`
    font-size: 30px;
    line-height: 42px;
  `}
`;

const ConcactUsBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 50px;
  background: #ffab18;
  border-radius: 30px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 25px;
  margin-top: 16px;
  cursor: pointer;
  &:hover {
    background: #ffbb46;
  }
  ${breakpoint('desktop')`
    width: 355px;
    height: 63px;
    border-radius: 36px;
    font-size: 24px;
    line-height: 33px;
    margin-top: 30px;

  `}
`;
