export const socialResponsibilityAdList = [
  {
    key: 0,
    adImg: 'socialResponsibility_ad1',
    adTitle: '严谨的态度呵护孩子的艺术之路',
    adContent:
      '“来音”一直以来秉持着严谨认真的态度在做音乐教育类的产品。我们深知，孩子就是一张白纸，每一笔都会在ta的身上留下印记。所以我们在设计产品、制定课程的时候都在多方求证，认真调研的基础上，输出更好的方案。“不能教错”是我们坚守的底线。',
  },
  {
    key: 1,
    adImg: 'socialResponsibility_ad2',
    adTitle: '优质平台创造社会价值',
    adContent:
      '“来⾳”是⼀个⾳乐教育科技品牌，通过“互联⽹+AI⼤数据”的⽅式，让学⾳乐更⾼效更有趣。来⾳的平台让学⽣能⾃主找到好⽼师，将先进的学习⽅法与AI算法相结合，帮学⽣⾄少节省⼀半的练琴时间，来⾳还提供了交流与⾃我展示的社区，让学⽣更爱学⾳乐，⾳乐教育⼯作者更容易赚到钱，进⽽促进⾳乐教育领域的发展。',
  },
  {
    key: 2,
    adImg: 'socialResponsibility_ad3',
    adTitle: '和伙伴共同成长',
    adContent:
      '“来音”是一家年轻的音乐产品公司。公司里有许多会多种乐器的同学，公司琴房是小伙伴们讨论学习乐器的地方。热闹的琴房里既有为了能更好的做出音乐产品的在学习乐器的“音乐小白”和他们的“老师”，也有讨论热烈讨论产品方案的同事们。除此之外，公司还有在各专业的内部培训和分享。“互助、共勉、高速成长”是公司的企业文化之一。',
  },
];
