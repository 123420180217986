import React, { useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';
import { Modal, message } from 'antd';

import { useNavigate } from 'react-router-dom';

import useIsPhone from '../../../hooks/useIsPhone';
import { DownloadIcon } from '../../../icons';

import { isAndroidFunc } from '../../../utils/commonFunc';

export default function ProjectBlock(props) {
  const { blockBgColor = '#fff', projectBlockData } = props;
  const navigate = useNavigate();
  const isPhone = useIsPhone();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOpenBundleApp = () => {
    navigate(_.get(projectBlockData, 'bundleApp.detailPath'));
  };
  const infoTitleWidth = _.chain(projectBlockData)
    .get('infoTitleLength')
    .multiply(14)
    .value();

  const infoTitleLetterSpacing = (title) =>
    (infoTitleWidth - _.size(title) * 14) / (_.size(title) - 1);

  const handleOpenAppStoreOrAppMarket = (
    iOSDownloadLink,
    androidMarketLink
  ) => {
    const isAndroid = isAndroidFunc();
    if (isAndroid) {
      if (androidMarketLink) {
        window.location.href = androidMarketLink;
      } else {
        messageApi.open({
          type: 'info',
          content: '该APP正在开发中，敬请期待～',
          className: 'custom-class',
          style: {
            marginTop: '12vh',
          },
        });
      }
    } else {
      if (iOSDownloadLink) {
        window.location.href = iOSDownloadLink;
      } else {
        messageApi.open({
          type: 'info',
          content: '该APP正在开发中，敬请期待～',
          className: 'custom-class',
          style: {
            marginTop: '12vh',
          },
        });
      }
    }
  };
  return (
    <Wrapper
      background={blockBgColor}
      alt={_.get(projectBlockData, 'keywords')}
    >
      {contextHolder}
      <ProjectBlockWrapper alt={_.get(projectBlockData, 'keywords')}>
        {isPhone && (
          <ImageWrapper alt={_.get(projectBlockData, 'keywords')}>
            <AppImage
              src={require(`../images/${_.get(
                projectBlockData,
                'appImg'
              )}_mobile.png`)}
            />
          </ImageWrapper>
        )}
        <ProjectBlockLeftContent alt={_.get(projectBlockData, 'keywords')}>
          <PhoneImage
            alt={_.get(projectBlockData, 'appName')}
            src={require(`../images/${_.get(
              projectBlockData,
              'adImgKey'
            )}1.jpg`)}
          />
          <PhoneImage
            alt={_.get(projectBlockData, 'appName')}
            className='phoneImageRight'
            src={require(`../images/${_.get(
              projectBlockData,
              'adImgKey'
            )}2.jpg`)}
          />
          {!isPhone && (
            <PositionBgImg
              bgImgInfo={_.get(projectBlockData, 'bgImgInfo')}
              src={require(`../images/${_.get(projectBlockData, 'bgImg')}.png`)}
            />
          )}
        </ProjectBlockLeftContent>
        <ProjectBlockRightContent alt={_.get(projectBlockData, 'keywords')}>
          {!isPhone && (
            <ImageWrapper>
              <AppImage
                src={require(`../images/${_.get(
                  projectBlockData,
                  'appImg'
                )}.png`)}
              />
            </ImageWrapper>
          )}
          <AppInfo>
            {_.map(_.get(projectBlockData, 'introduction'), (item, index) => (
              <Introduction
                key={item}
                style={{
                  marginBottom:
                    index ===
                    _.size(_.get(projectBlockData, 'introduction')) - 1
                      ? isPhone
                        ? 8
                        : 20
                      : 0,
                }}
              >
                {item}
              </Introduction>
            ))}
            {_.map(_.get(projectBlockData, 'infos'), ({ title, info }) => (
              <InfoItem key={title}>
                •
                <InfoTitle
                  style={{
                    width: infoTitleWidth,
                    wordBreak: 'keep-all',
                    letterSpacing: infoTitleLetterSpacing(title),
                  }}
                >
                  {title}
                </InfoTitle>
                {info}
              </InfoItem>
            ))}
            {_.map(_.get(projectBlockData, 'subIntroduction'), (item) => (
              <Introduction key={item}>{item}</Introduction>
            ))}
          </AppInfo>
          <AppDownloadBtnList>
            <DownloadBtn
              onClick={() => {
                if (isPhone) {
                  handleOpenAppStoreOrAppMarket(
                    _.get(projectBlockData, 'iOSDownloadLink'),
                    _.get(projectBlockData, 'androidMarket')
                  );
                } else {
                  setIsModalOpen(true);
                }
              }}
            >
              <DownloadIcon fill='#fff' size={28} style={{ marginRight: 7 }} />
              下载应用
            </DownloadBtn>
            {_.get(projectBlockData, 'bundleApp') && (
              <OtherBtn onClick={handleOpenBundleApp}>
                {_.get(projectBlockData, 'bundleApp.name')}
              </OtherBtn>
            )}
          </AppDownloadBtnList>
        </ProjectBlockRightContent>
      </ProjectBlockWrapper>
      <Modal
        centered
        title={
          <ModalTitle>
            <ModalTitleImg
              src={require(`../images/${_.get(
                projectBlockData,
                'modalTitleImg'
              )}.png`)}
            />
          </ModalTitle>
        }
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={isPhone ? 286 : 450}
        style={{ zIndex: 3500, height: 228 }}
        maskStyle={{ zIndex: 1000 }}
      >
        <ModalContent>
          {!_.get(projectBlockData, 'iOSDownloadLink') &&
          !_.get(projectBlockData, 'androidMarketLink')
            ? '该APP正在开发中，敬请期待～'
            : `在应用商店内搜索 “${_.get(
                projectBlockData,
                'appName'
              )}” 下载安装`}
        </ModalContent>
      </Modal>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.background};
`;

const ProjectBlockWrapper = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  padding: 40px 16px;
  ${breakpoint('desktop')`
    width: 1200px;
    height: 765px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
  `}
`;

const ProjectBlockLeftContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-column-gap: 16px;
  z-index: 5;
  ${breakpoint('desktop')`
    flex: 1;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    grid-column-gap: 0;
  `}
`;

const PhoneImage = styled.img`
  width: 100%;
  object-fit: cover;
  z-index: 10;
  background: #d8d8d8;
  box-shadow: 0px 3px 14px 2px rgba(178, 72, 0, 0.1);
  border-radius: 29px;
  border: 4px solid #ffffff;
  ${breakpoint('desktop')`
    height: 585px;
    width: 280px;
    object-fit: cover;
    background: #D8D8D8;
    box-shadow: 0px 3px 14px 2px rgba(178,72,0,0.1);
    border-radius: 29px;
    border: 8px solid #FFFFFF;
    &.phoneImageRight{
        margin-left: 30px;
    }
  `}
`;

const PositionBgImg = styled.img`
  width: ${(props) => props.bgImgInfo.width + 'px'};
  height: ${(props) => props.bgImgInfo.height + 'px'};
  bottom: ${(props) => props.bgImgInfo.bottom + 'px'};
  right: ${(props) => props.bgImgInfo.right + 'px'};
  z-index: 5;
  ${breakpoint('desktop')`
    position: absolute;
  `}
`;

const ProjectBlockRightContent = styled.div`
  padding: 16px 0;
  ${breakpoint('desktop')`
    margin-left: 50px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
  `}
`;

const ImageWrapper = styled.div`
  height: 65px;
  max-width: 100%;
  margin-bottom: 25px;
  ${breakpoint('desktop')`
    height: 107px;
    margin-bottom: 0;
  `}
`;

const AppImage = styled.img`
  height: 100%;
  object-fit: cover;
  ${breakpoint('desktop')`
    height: 107px;
    margin-bottom: 0;
    object-fit: cover;
  `}
`;

const AppInfo = styled.div`
  ${breakpoint('desktop')`
    flex: 1;
    margin: 26px 0;
    display: flex;
    flex-direction: column;
  `}
`;

const Introduction = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2c2e34;
  line-height: 23px;
  ${breakpoint('desktop')`
    font-size: 16px;
  `}
`;

const InfoItem = styled.div`
  font-size: 14px;
  font-weight: 200;
  color: #4b4f5a;
  line-height: 23px;
  margin-bottom: 8px;
  ${breakpoint('desktop')`
    display: flex;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
  `}
`;

const InfoTitle = styled.span`
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2c2e34;
  line-height: 20px;
  margin-right: 10px;
  margin-left: 5px;
  ${breakpoint('desktop')`
    margin-right: 20px;
  `}
`;

const AppDownloadBtnList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${breakpoint('desktop')`
    flex-direction: row; 
    justify-content: start;
  `}
`;

const DownloadBtn = styled.div`
  width: 320px;
  height: 50px;
  background: #ffab18;
  border-radius: 30px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
  &:hover {
    background: #ffbb46;
  }
  ${breakpoint('desktop')`
    width: 240px;
    height: 70px;
    border-radius: 42px;
    font-size: 24px;
    margin-top: 0;
  `}
`;

const OtherBtn = styled.div`
  width: 320px;
  height: 50px;
  border-radius: 30px;
  border: 1px solid #ffb453;
  font-size: 18px;
  color: #ff950a;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0 0 0;
  cursor: pointer;
  ${breakpoint('desktop')`
    width: 275px;
    height: 70px;
    border-radius: 42px;
    margin: 0 0 0 25px;
    font-size: 24px;
  `}
`;

const ModalTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalTitleImg = styled.img`
  height: 97px;
  margin-top: -38px;
  ${breakpoint('desktop')`height: 116px;`}
`;

const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111324;
  line-height: 20px;
  margin: 33px 0 55px 0;
  ${breakpoint('desktop')`
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #2c2e34;
    line-height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 35px 0 55px 0;
  `}
`;
