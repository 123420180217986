import React from 'react';
import Icon from './Icon';

export default function MoreIcon(props) {
  return (
    <Icon {...props} iconfont>
      <path d='M1038.507236 469.333333c23.438222 0 42.382222 18.944 42.382222 42.666667 0 23.552-18.773333 42.666667-42.382222 42.666667H42.382791A42.439111 42.439111 0 0 1 0.000569 512c0-23.552 18.773333-42.666667 42.382222-42.666667h996.124445zM1038.507236 42.666667c23.438222 0 42.382222 18.944 42.382222 42.666666 0 23.552-18.773333 42.666667-42.382222 42.666667H42.382791A42.439111 42.439111 0 0 1 0.000569 85.333333C0.000569 61.781333 18.773902 42.666667 42.382791 42.666667h996.124445zM1038.507236 896c23.438222 0 42.382222 18.944 42.382222 42.666667 0 23.552-18.773333 42.666667-42.382222 42.666666H42.382791A42.439111 42.439111 0 0 1 0.000569 938.666667c0-23.552 18.773333-42.666667 42.382222-42.666667h996.124445z' />
    </Icon>
  );
}
