import React, { useEffect } from 'react';
import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';

import useIsPhone from '../../hooks/useIsPhone';

import HeaderImg from '../../components/HeaderImg';

import { socialResponsibilityAdList } from './config';
import _ from 'lodash';

export default function SocialResponsibility() {
  const isPhone = useIsPhone();
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  }, []);
  return (
    <Wrapper>
      <HeaderImg
        url={require(`./images/${isPhone ? 'phone' : 'pc'}_header.png`)}
      />
      <AdBlockWrapper>
        {_.map(
          socialResponsibilityAdList,
          ({ key, adImg, adTitle, adContent }) => (
            <AdBlockContent key={key}>
              {(key % 2 === 0 || isPhone) && (
                <AdImage src={require(`./images/${adImg}.png`)} />
              )}
              <AdContentWrapper>
                <AdTitle className={key % 2 !== 0 && !isPhone ? 'flexEnd' : ''}>
                  {adTitle}
                </AdTitle>
                <AdContent>{adContent}</AdContent>
              </AdContentWrapper>
              {key % 2 !== 0 && !isPhone && (
                <AdImage src={require(`./images/${adImg}.png`)} />
              )}
            </AdBlockContent>
          )
        )}
      </AdBlockWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AdBlockWrapper = styled.div`
  padding: 30px 16px;
  ${breakpoint('desktop')`
    width: 1200px;
    height: max-content;
    padding: 90px 0;
  `}
`;

const AdBlockContent = styled.div`
  /* width: 100%;
  height: max-content;
  flex-direction: column;
  padding: 40px 16px; */
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
  ${breakpoint('desktop')`
    width: 1200px;
    height: max-content;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin-bottom: 130px;
    &:last-child{
      margin-bottom: 0;
    }
  `}
`;

const AdImage = styled.img`
  width: 100%;
  /* height: max-content; */
  object-fit: cover;
  ${breakpoint('desktop')`
    width: 550px;
    height: 350px;
    box-shadow: 0px 2px 12px 0px rgba(101, 43, 0, 0.08);
    border-radius: 14px;
  `}
`;

const AdContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${breakpoint('desktop')`
    width: 600px;
    flex-direction: column;
    justify-content: center;
  `}
`;

const AdTitle = styled.div`
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #2c2e34;
  line-height: 25px;
  margin-top: 12px;
  margin-bottom: 7px;
  ${breakpoint('desktop')`
    font-size: 24px;
    line-height: 33px;
    margin-top: 0;
    margin-bottom: 20px;
    &.flexEnd{
      display: flex;
      justify-content: flex-end;
    }
  `}
`;

const AdContent = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4b4f5a;
  line-height: 28px;
  ${breakpoint('desktop')`
    font-size: 16px;
    line-height: 34px;
  `}
`;
