import React from 'react';
import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';
import { useNavigate } from 'react-router-dom';
import Clipboard from 'clipboard';
import { message } from 'antd';
import websiteName from '../asset/images/website_name.png';
import useIsPhone from '../hooks/useIsPhone';

export default function PageFooter() {
  const navigate = useNavigate();
  const isPhone = useIsPhone();

  const [messageApi, contextHolder] = message.useMessage();

  const handleToJoinUs = () => {
    navigate('/aboutUs?jumpTo=joinUs');
  };

  const handleToMoreApp = () => {
    navigate('/moreApplications');
  };

  const handleCopyEmail = () => {
    const clipboard = new Clipboard('.copy', {
      text: () => 'hezuo@quthing.com',
    });
    clipboard.on('success', function () {
      messageApi.open({
        type: 'success',
        content: '合作联系邮箱已成功复制到剪切板，请及时发送邮件与我们联系',
        className: 'custom-class',
        style: {
          marginTop: '12vh',
        },
      });
      clipboard.destroy();
    });
  };

  return (
    <Wrapper>
      {contextHolder}
      <FooterTopAreaWrapper>
        <FooterTopArea>
          <WebsiteName src={websiteName} />
          {isPhone ? (
            <Infos>
              <Info onClick={handleToJoinUs}>加入我们</Info>
              <Info onClick={handleToMoreApp}>更多应用</Info>
              <Info className='copy' onClick={handleCopyEmail}>
                商务合作
              </Info>
            </Infos>
          ) : (
            <Infos>
              <Info>
                更多信息<SubInfo onClick={handleToJoinUs}>加入我们</SubInfo>
                <SubInfo onClick={handleToMoreApp}>更多应用</SubInfo>
              </Info>
              <Info>
                商务合作
                <SubInfo className='copy' onClick={handleCopyEmail}>
                  官方邮箱：hezuo@quthing.com
                </SubInfo>
              </Info>
            </Infos>
          )}
        </FooterTopArea>
      </FooterTopAreaWrapper>
      <FooterBottomAreaWrapper>
        <FooterBottomArea>
          <FooterBottomAreaInfo>
            <CopyrightIconImg src={require('../asset/images/copyright_icon.png')} />
            2023 北京夕航科技有限公司版权所有|
            <GovInfo onClick={() => window.open('https://beian.miit.gov.cn/', '_blank')}>
              京ICP备18026356号-1
            </GovInfo>
            |
          </FooterBottomAreaInfo>
          <FooterBottomAreaInfo style={{ marginTop: isPhone ? 12 : 0 }}>
            <PublicSecurityRecordNoImg
              src={require('../asset/images/public_security_record_no.png')}
            />
            <GovInfo
              onClick={() =>
                window.open(
                  'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802030270',
                  '_blank'
                )
              }
            >
              京公网安备 11010802030270号
            </GovInfo>
          </FooterBottomAreaInfo>
        </FooterBottomArea>
      </FooterBottomAreaWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 244px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 10;
  ${breakpoint('desktop')`
    height: 214px;
  `}
`;

const FooterTopAreaWrapper = styled.div`
  width: 100%;
  height: 146px;
  background: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  ${breakpoint('desktop')`
    height: 154px;
  `}
`;

const FooterTopArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 0 16px;
  ${breakpoint('desktop')`
    max-width: 1200px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0;
  `}
`;

const WebsiteName = styled.img`
  width: 276px;
  height: 30px;
  ${breakpoint('desktop')`
    width: 385px;
    height: 40px;
  `}
`;

const Infos = styled.div`
  display: flex;
`;

const Info = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #4b4f5a;
  line-height: 20px;
  margin-right: 45px;
  ${breakpoint('desktop')`
    font-size: 16px;
  `}
`;

const FooterBottomAreaWrapper = styled.div`
  width: 100%;
  height: 98px;
  background-color: #f3f3f4;
  display: flex;
  justify-content: center;
  align-items: center;
  ${breakpoint('desktop')`
    height: 60px;
  `}
`;

const FooterBottomArea = styled.div`
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #9c9ea5;
  line-height: 17px;
  padding: 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${breakpoint('desktop')`
    max-width: 1200px;
    flex-direction: row;
  `}
`;

const SubInfo = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2c2e34;
  line-height: 20px;
  margin-top: 16px;
  cursor: pointer;
  &:hover {
    color: #ff950a;
  }
`;

const CopyrightIconImg = styled.img`
  width: 16px;
  height: 14px;
  margin-right: 3px;
  ${breakpoint('desktop')`
    width: 17px;
    height: 17px;
    margin-right: 4px;
  `}
`;

const PublicSecurityRecordNoImg = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 3px;
  ${breakpoint('desktop')`
    width: 18px;
    height: 20px;
    margin-left: 100px;
    margin-right: 9px;
  `}
`;

const FooterBottomAreaInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GovInfo = styled.span`
  cursor: pointer;
  &:hover {
    color: #123400;
  }
`;
