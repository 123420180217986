import React from 'react';
import Icon from './Icon';

export default function CloseIcon(props) {
  return (
    <Icon {...props} iconfont>
      <path d='M201.681138 134.379296m34.479874 34.479874l620.637723 620.637723q34.479874 34.479874 0 68.959747l0 0q-34.479874 34.479874-68.959747 0l-620.637723-620.637723q-34.479874-34.479874 0-68.959747l0 0q34.479874-34.479874 68.959747 0Z' />
      <path d='M891.278609 203.339043m-34.479874 34.479874l-620.637723 620.637723q-34.479874 34.479874-68.959747 0l0 0q-34.479874-34.479874 0-68.959747l620.637723-620.637723q34.479874-34.479874 68.959747 0l0 0q34.479874 34.479874 0 68.959747Z' />
    </Icon>
  );
}
