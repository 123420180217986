import React from 'react';
import Icon from './Icon';

export default function NextPageIcon(props) {
  return (
    <Icon {...props} iconfont>
      <path d='M537.486222 166.343111a28.672 28.672 0 0 0 0 40.277333l315.050667 314.936889-315.278222 315.335111a28.842667 28.842667 0 0 0-3.299556 36.408889l3.242667 3.868445c11.093333 11.093333 29.354667 10.808889 40.277333-0.056889l334.620445-334.677333a28.046222 28.046222 0 0 0 7.452444-13.312 28.615111 28.615111 0 0 0-7.168-28.103112l-334.620444-334.620444a28.842667 28.842667 0 0 0-36.408889-3.242667l-3.868445 3.185778z' />
    </Icon>
  );
}
