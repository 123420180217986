import React from 'react';
import Icon from './Icon';

export default function DownloadIcon(props) {
  return (
    <Icon {...props} iconfont>
      <path d='M512 0c282.752 0 512 229.248 512 512s-229.248 512-512 512S0 794.752 0 512 229.248 0 512 0z m0 85.333333C276.352 85.333333 85.333333 276.352 85.333333 512s191.018667 426.666667 426.666667 426.666667 426.666667-191.018667 426.666667-426.666667S747.648 85.333333 512 85.333333z' />
      <path d='M508.16 279.253333a42.666667 42.666667 0 0 1 42.666667 42.666667l-0.042667 239.36 47.829333-47.786667a42.666667 42.666667 0 0 1 56.32-3.584l4.053334 3.584a42.666667 42.666667 0 0 1 0 60.330667l-120.704 120.661333a42.453333 42.453333 0 0 1-22.613334 11.861334l-5.034666 0.554666h-5.034667a42.496 42.496 0 0 1-27.648-12.373333l-120.661333-120.746667a42.666667 42.666667 0 0 1 60.330666-60.288l47.786667 47.786667 0.042667-239.36a42.666667 42.666667 0 0 1 42.666666-42.666667z' />
    </Icon>
  );
}
