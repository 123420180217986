import React from 'react';
import Icon from './Icon';

export default function RightIcon(props) {
  return (
    <Icon {...props} iconfont>
      <path
        d='M316.342857 26.404571A36.571429 36.571429 0 0 0 261.778286 74.971429l2.852571 3.181714L698.514286 512 264.630857 945.883429a36.571429 36.571429 0 0 0-2.852571 48.530285l2.852571 3.181715a36.571429 36.571429 0 0 0 48.530286 2.852571l3.181714-2.852571 459.702857-459.702858a36.571429 36.571429 0 0 0 2.889143-48.566857l-2.852571-3.181714L316.342857 26.441143z'
        opacity='.8'
      />
    </Icon>
  );
}
