import React from 'react';
import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';

export default function HeaderImg(props) {
  const { url, ...rest } = props;
  return <Image src={url} {...rest} />;
}

const Image = styled.img`
  height: 170px;
  width: 100%;
  object-fit: cover;
  ${breakpoint('desktop')`
    height: 400px;
    width: 100%;
  `}
`;
