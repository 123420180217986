import React, { useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';
import { Modal, message } from 'antd';

import useIsPhone from '../../../hooks/useIsPhone';
import { LeftIcon, RightIcon, DownloadIcon } from '../../../icons';
import { isAndroidFunc } from '../../../utils/commonFunc';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
SwiperCore.use([Autoplay, Navigation, Pagination]);

export default function BannerList(props) {
  const { bannerListData, bannerListDataOfPhone } = props;
  const isPhone = useIsPhone();

  //   const [bannerPage, setBannerPage] = useState(0);
  //   const [posiLeft, setPosiLeft] = useState(0);
  //   const [swapIndex, setSwapIndex] = useState(0); //轮播index
  const [messageApi, contextHolder] = message.useMessage();

  // useEffect(() => {
  //   if (!isPhone) {
  //     setTimeout(() => {
  //       if (swapIndex === 0) {
  //         setSwapIndex(1);
  //         handleChangeBannerIndex(1);
  //       } else if (swapIndex === 1) {
  //         setSwapIndex(2);
  //         handleChangeBannerIndex(2);
  //       } else if (swapIndex === 2) {
  //         setSwapIndex(0);
  //         handleChangeBannerIndex(0);
  //       }
  //     }, 3000);
  //   }
  // }, [swapIndex, isPhone]);

  // 上四app
  //   const handleToPreview = () => {
  //     if (bannerPage > 0) {
  //       setBannerPage(bannerPage - 1);
  //       setPosiLeft(posiLeft + 1040);
  //     }
  //   };
  //   // 下四app
  //   const handleToNext = () => {
  //     if (bannerPage < 2) {
  //       setBannerPage(bannerPage + 1);
  //       setPosiLeft(posiLeft - 1040);
  //     }
  //   };

  //   const handleChangeBannerIndex = (index) => {
  //     setBannerPage(index);
  //     setPosiLeft(index * -1040);
  //   };

  const [currentAppName, setCurrentAppName] = useState();
  const [currentAppImg, setCurrentAppImg] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOpenAppStoreOrAppMarket = (
    iOSDownloadLink,
    androidMarketLink
  ) => {
    const isAndroid = isAndroidFunc();
    if (isAndroid) {
      if (androidMarketLink) {
        window.location.href = androidMarketLink;
      } else {
        messageApi.open({
          type: 'info',
          content: '该APP正在开发中，敬请期待～',
          className: 'custom-class',
          style: {
            marginTop: '12vh',
          },
        });
      }
    } else {
      if (iOSDownloadLink) {
        window.location.href = iOSDownloadLink;
      } else {
        messageApi.open({
          type: 'info',
          content: '该APP正在开发中，敬请期待～',
          className: 'custom-class',
          style: {
            marginTop: '12vh',
          },
        });
      }
    }
  };

  return (
    <Wrapper>
      {contextHolder}
      <BannerListWrapper>
        {/* 切换按钮 left */}
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: isPhone ? 20 : 50,
            marginBottom: 40,
          }}
        >
          {!isPhone && (
            <PrevPreviewWrapper>
              <LeftIcon fill='#FF950A' size={16} />
            </PrevPreviewWrapper>
          )}
          {!isPhone && (
            <SwiperList
              spaceBetween={12}
              slidesPerView={'auto'}
              loop={true}
              loopedSlides={4}
              autoplay={{ delay: 3000, disableOnInteraction: false }}
              slidesPerGroup={4}
              navigation={{
                prevEl: `${PrevPreviewWrapper}`,
                nextEl: `${PrevNextWrapper}`,
              }}
              pagination={{
                el: `${Dots}`,
                clickable: true,
              }}
            >
              {_.map(
                bannerListData,
                ({
                  key,
                  appBgImg,
                  appIcon,
                  appName,
                  appIntr,
                  intrEmoji,
                  modalTitleImg,
                  iOSDownloadLink,
                  androidMarket,
                }) => {
                  return (
                    <SwiperSlide key={key}>
                      {appName && (
                        <AppItem key={key}>
                          <AppBgImg>
                            <img
                              alt=''
                              src={require(`../images/${appBgImg}.png`)}
                            />
                          </AppBgImg>
                          <AppIconImg>
                            <img
                              alt=''
                              src={require(`../images/${appIcon}.png`)}
                            />
                          </AppIconImg>
                          <AppName>{appName}</AppName>
                          <AppIntr>
                            {_.map(appIntr, (item) => (
                              <div key={item}>{item}</div>
                            ))}
                          </AppIntr>
                          <Emoji>{intrEmoji}</Emoji>
                          <DownLoadBtn
                            onClick={() => {
                              if (isPhone) {
                                handleOpenAppStoreOrAppMarket(
                                  iOSDownloadLink,
                                  androidMarket
                                );
                              } else {
                                setCurrentAppImg(modalTitleImg);
                                setCurrentAppName(appName);
                                setIsModalOpen(true);
                              }
                            }}
                          >
                            <DownloadIcon
                              fill='#fff'
                              size={25}
                              style={{ marginRight: 7 }}
                            />
                            下载应用
                          </DownLoadBtn>
                        </AppItem>
                      )}
                    </SwiperSlide>
                  );
                }
              )}
            </SwiperList>
          )}

          {isPhone && (
            <SwiperList
              spaceBetween={12}
              slidesPerView={'auto'}
              loop={true}
              loopedSlides={4}
              autoplay={{ delay: 3000, disableOnInteraction: false }}
            >
              {_.map(
                bannerListDataOfPhone,
                ({
                  key,
                  appBgImg,
                  appIcon,
                  appName,
                  appIntr,
                  intrEmoji,
                  modalTitleImg,
                  iOSDownloadLink,
                  androidMarket,
                }) => {
                  return (
                    <SwiperSlide key={key}>
                      <AppItem key={key}>
                        <AppBgImg>
                          <img
                            alt=''
                            src={require(`../images/${appBgImg}.png`)}
                          />
                        </AppBgImg>
                        <AppIconImg>
                          <img
                            alt=''
                            src={require(`../images/${appIcon}.png`)}
                          />
                        </AppIconImg>
                        <AppName>{appName}</AppName>
                        <AppIntr>
                          {_.map(appIntr, (item) => (
                            <div key={item}>{item}</div>
                          ))}
                        </AppIntr>
                        <Emoji>{intrEmoji}</Emoji>
                        <DownLoadBtn
                          onClick={() => {
                            if (isPhone) {
                              handleOpenAppStoreOrAppMarket(
                                iOSDownloadLink,
                                androidMarket
                              );
                            } else {
                              setCurrentAppImg(modalTitleImg);
                              setCurrentAppName(appName);
                              setIsModalOpen(true);
                            }
                          }}
                        >
                          <DownloadIcon
                            fill='#fff'
                            size={25}
                            style={{ marginRight: 7 }}
                          />
                          下载应用
                        </DownLoadBtn>
                      </AppItem>
                    </SwiperSlide>
                  );
                }
              )}
            </SwiperList>
          )}
          {/* 切换按钮 right */}
          {!isPhone && (
            <PrevNextWrapper>
              <RightIcon fill='#FF950A' size={16} />
            </PrevNextWrapper>
          )}
        </div>
        {!isPhone && <Dots />}
      </BannerListWrapper>

      <Modal
        centered
        title={
          <ModalTitle>
            <ModalTitleImg
              src={
                currentAppImg ? require(`../images/${currentAppImg}.png`) : ''
              }
            />
          </ModalTitle>
        }
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={isPhone ? 286 : 450}
        style={{ zIndex: 3500, height: 228 }}
        maskStyle={{ zIndex: 1000 }}
      >
        <ModalContent>
          在应用商店内搜索 “{currentAppName}” 下载安装
        </ModalContent>
      </Modal>
    </Wrapper>
  );
}
const SwiperList = styled(Swiper)`
  width: 100%;
  height: max-content;
  padding: 10px 0;
  margin: 0;
  transform: translate3d(0, 0, 0);
  overflow: hidden;
  ${breakpoint('desktop')`
    width: 84%;
    padding:10px 10px 10px 5px;
  `}
  .swiper-wrapper {
    transform: translate3d(0, 0, 0);
  }
  .swiper-slide {
    /* transform:translate3d(0,0,0) */
    width: 240px;
    height: 420px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;
`;

const BannerListWrapper = styled.div`
  width: 100%;
  ${breakpoint('desktop')`
    width: 1200px;
    height: 610px;
    display: flex;
    flex-direction: column;
    align-items: center; 
  `}
`;

// const BannerListContent = styled.div`
//   width: 100%;
//   height: max-content;
//   display: flex;
//   overflow-x: scroll;
//   padding: 10px 0;
//   ::-webkit-scrollbar {
//     display: none;
//   }

//   ${breakpoint('desktop')`
//     width: 1030px;
//     height: 440px;
//     position: relative;
//     overflow-x: hidden;
//   `}
// `;

// const BannerListContentWrapper = styled.div`
//   width: max-content;
//   height: 420px;
//   display: flex;
//   transition: all 0.5s;
//   ${breakpoint('desktop')`
//     position: absolute;
//     left: ${(props) => `${props.posiLeft}px`};
//     margin-left: 5px;
//   `}/* position: relative;
//   .swiper-wrapper {
//     transition-timing-function: linear;
//   } */
// `;

const AppItem = styled.div`
  position: relative;
  width: 240px;
  height: 420px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 2px 12px 0px rgba(101, 43, 0, 0.08);
  border-radius: 14px;
  margin-left: 12px;
  transform: translate3d(0, 0, 0);
  /* :last-child {
    margin-right: 12px;
  } */
  ${breakpoint('desktop')`
    margin-left: 20px;
    :first-child{
        margin-left: 0; 
    }
    :last-child {
        margin-right: 0;
    } 
  `};
`;

const AppBgImg = styled.div`
  width: 100%;
  > img {
    width: 100%;
  }
`;

const AppIconImg = styled.div`
  width: 64px;
  height: 64px;
  margin-top: -47px;
  > img {
    width: 100%;
  }
`;

const AppName = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #35374e;
  margin: 4px 0 8px 0;
`;

const AppIntr = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4b4f5a;
`;

const Emoji = styled.div``;

const DownLoadBtn = styled.div`
  width: 180px;
  height: 46px;
  background: #ffab18;
  border-radius: 30px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 22px;
  cursor: pointer;
  &:hover {
    background: #ffbb46;
  }
`;

const PrevNextWrapper = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: rgba(255, 149, 10, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;
const PrevPreviewWrapper = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: rgba(255, 149, 10, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const ModalTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalTitleImg = styled.img`
  height: 97px;
  margin-top: -38px;
  ${breakpoint('desktop')`height: 116px;`}
`;

const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111324;
  line-height: 20px;
  margin: 33px 0 55px 0;
  ${breakpoint('desktop')`
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #2c2e34;
    line-height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 35px 0 55px 0;
  `}
`;

const Dots = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: rgba(151, 151, 151, 0.3);
    margin-right: 30px;
    opacity: 1;
    &:last-child {
      margin-right: 0;
    }
  }
  .swiper-pagination-bullet-active {
    background: rgba(151, 151, 151, 0.7);
  }
`;

// const Dot = styled.div`
//   width: 10px;
//   height: 10px;
//   border-radius: 50%;
//   background: rgba(151, 151, 151, 0.3);
//   margin-right: 30px;
//   cursor: pointer;
//   &:last-child {
//     margin-right: 0;
//   }
//   &.active {
//     background: rgba(151, 151, 151, 0.7);
//   }
// `;
