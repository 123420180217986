import React, { useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { Popup } from 'react-vant';

import useIsPhone from '../hooks/useIsPhone';
import { MoreIcon, CloseIcon } from '../icons';

import Menu from './Menu';

import websiteName from '../asset/images/website_name.png';

const menuList = [
  {
    key: 'ourProducts',
    path: '/ourProducts',
    menuName: '音乐类应用',
    paths: ['/ourProducts', '/laiyinlianqinTeacher'],
  },
  {
    key: 'moreApplications',
    path: '/moreApplications',
    menuName: '工具类应用',
  },
  { key: 'aboutUs', path: '/aboutUs', menuName: '关于我们' },
  {
    key: 'socialResponsibility',
    path: '/socialResponsibility',
    menuName: '社会责任',
  },
  //   {
  //     key: 'moreApplications',
  //     path: '/moreApplications',
  //     menuName: '更多应用',
  //   },
];

export default function PageHeader() {
  const isPhone = useIsPhone();
  const [visible, setVisible] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  const currentRoutePath = _.get(location, 'pathname');

  const handleNavigate = (routeKey) => {
    navigate(`${routeKey}`);
    setVisible(false);
  };
  return (
    <Wrapper style={{ zIndex: isPhone ? 3000 : 10 }}>
      <HeaderContent>
        <WebsiteName src={websiteName} />
        {isPhone ? (
          <React.Fragment>
            {visible ? (
              <CloseIcon
                size={20}
                fill='#4B4F5A'
                onClick={() => setVisible(false)}
              />
            ) : (
              <MoreIcon
                size={20}
                fill='#4B4F5A'
                onClick={() => setVisible(true)}
              />
            )}
            <Popup
              position='top'
              visible={visible}
              onClose={() => setVisible(false)}
            >
              <MenuList>
                {_.map(menuList, ({ key, path, menuName, paths = [] }) => {
                  const isActiveMenu =
                    currentRoutePath === path ||
                    _.includes(paths, currentRoutePath);
                  return (
                    <MenuListItem
                      key={key}
                      className={isActiveMenu ? 'active' : ''}
                      onClick={() => handleNavigate(path)}
                    >
                      {menuName}
                    </MenuListItem>
                  );
                })}
              </MenuList>
            </Popup>
          </React.Fragment>
        ) : (
          <Menu />
        )}
      </HeaderContent>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 44px;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  z-index: 10;
  border-bottom: 1px solid #f2f2f2;
  ${breakpoint('desktop')`
    height: 72px;
  `}
`;

const HeaderContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${breakpoint('desktop')`
    max-width: 1200px;
    padding: 0;
  `}
`;

const WebsiteName = styled.img`
  width: 276px;
  height: 30px;
  ${breakpoint('desktop')`
    width: 385px;
    height: 40px;
  `}
`;

const MenuList = styled.div`
  height: 261px;
  padding-top: 44px;
  background: #fff;
`;

const MenuListItem = styled.div`
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2c2e34;
  line-height: 22px;
  border-bottom: 1px solid #f2f2f2;
  &:last-child {
    border-bottom: none;
  }
  &.active {
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ff950a;
  }
`;
