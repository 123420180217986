import { useState, useEffect } from 'react';

export default function useIsPhone() {
  const isClient = typeof window === 'object';

  function getWidth() {
    return isClient ? window.innerWidth : 0;
  }

  function getHeight() {
    return isClient ? window.innerHeight : 0;
  }

  const [windowWidth, setWindowWidth] = useState(getWidth);
  const [windowHeight, setWindowHeight] = useState(getHeight);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowWidth(getWidth());
      setWindowHeight(getHeight());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowWidth <= 1200 || windowHeight > windowWidth;
}
