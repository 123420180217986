import React from 'react';
import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';

export default function BlockTitleImage(props) {
  const { url, ...rest } = props;
  return <Image src={url} {...rest} />;
}

const Image = styled.img`
  height: 50px;
  max-width: 100%;
  margin-top: 16px;
  object-fit: cover;
  ${breakpoint('desktop')`
    height: 82px;
    margin-top: 63px;
  `}
`;
