import React, { useEffect } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';
import { useNavigate } from 'react-router-dom';

import AppIntroBlock from './components/AppIntroBlock';
import BlockTitleImage from '../../components/BlockTitleImage';
import { NextPageIcon } from '../../icons';
import useIsPhone from '../../hooks/useIsPhone';

const adList = [
  { key: 1, adImg: 'laiyinlianqin_teacher_ad1_img', adInfo: '海量生源' },
  { key: 2, adImg: 'laiyinlianqin_teacher_ad2_img', adInfo: '时间灵活' },
  { key: 3, adImg: 'laiyinlianqin_teacher_ad3_img', adInfo: '自行定价' },
];

const laiyinlianqin_teacher_app = {
  appName: '来音练琴老师端',
  modalTitleImg: 'laiyinlianqin_teacher_modal_title_img',
  appImg: 'laiyinlianqin_teacher_app_img',
  bgImg: 'laiyinlianqin_teacher_bg_img',
  adImgKey: 'laiyinlianqin_teacher_ad',
  iOSDownloadLink: 'https://apps.apple.com/cn/app/id1565160122',
  androidMarket: 'market://details?id=com.xihang.partnertrainingteacher',
  bgImgInfo: {
    width: 748,
    height: 524,
    bottom: -6,
    right: -62,
  },
  introduction: [
    '来音练琴是一个线上乐器陪练平台，老师可以在平台接受学生的约课，也可以邀请自有学生到平台上私教课。对于平台老师',
  ],
  infos: [
    {
      title: '生源稳定',
      info: '来音练琴拥有百万学员规模，学生可自主约老师的课。',
    },
    {
      title: '课时费自己定',
      info: '老师自定课时费，工资周结。',
    },
    {
      title: '时间自由',
      info: '平台管理人性化，老师自由选择排课时间，不强制开课。',
    },
  ],
  infoTitleLength: 6,
};

export default function LaiyinlianqinTeacher() {
  const isPhone = useIsPhone();
  const navigate = useNavigate();
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  }, []);

  const handleToProductsPage = () => {
    navigate('/ourProducts');
  };

  return (
    <Wrapper>
      {!isPhone && (
        <HeaderPathWrapper>
          <HeaderPath>
            <ToOurProductsPage onClick={handleToProductsPage}>
              我们的产品
            </ToOurProductsPage>
            <NextPageIcon
              fill='#979797'
              size={18}
              style={{ margin: '0 13px 0 6px' }}
            />
            <div>来音练琴老师端</div>
          </HeaderPath>
        </HeaderPathWrapper>
      )}
      <AppIntroBlock projectBlockData={laiyinlianqin_teacher_app} />

      <BlockWrapper bgColor='#FFFCF5'>
        <BlockTitleImage url={require(`./images/whyChooseLaiyin_img.png`)} />
        <BlockContentWrapper>
          <AdBlock>
            {_.map(adList, ({ key, adImg, adInfo }) => {
              return (
                <AdBlockItem key={key}>
                  <AdImg src={require(`./images/${adImg}.png`)} />
                  <AdContent>{adInfo}</AdContent>
                </AdBlockItem>
              );
            })}
          </AdBlock>
        </BlockContentWrapper>
      </BlockWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const HeaderPathWrapper = styled.div`
  width: 100%;
  height: 56px;
  background: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeaderPath = styled.div`
  width: 1200px;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2c2e34;
  line-height: 22px;
`;

const BlockWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${(props) => props.bgColor || '#fff'};
`;

const BlockContentWrapper = styled.div`
  display: flex;
  width: 100%;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  ${breakpoint('desktop')`
  width: 1200px;
  height: max-content;
  justify-content: center;
`}
`;

const AdBlock = styled.div`
  width: max-content;
  display: flex;
  margin: 24px 0 40px 0;
  padding: 0 16px;
  ${breakpoint('desktop')`
    padding-left: 0;
    margin: 50px 0 90px 0;
  `}
`;

const AdBlockItem = styled.div`
  width: 313px;
  margin-right: 12px;
  &:last-child {
    margin-right: 0;
  }
  ${breakpoint('desktop')`
   width: 390px;
   margin-right: 15px;
  `}
`;

const AdImg = styled.img`
  object-fit: cover;
  width: 100%;
  height: auto;
`;

const AdContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #2c2e34;
  font-size: 16px;
  line-height: 22px;
  margin-top: 24px;
  ${breakpoint('desktop')`
    font-size: 24px;
    line-height: 33px;
    margin-top: 40px;
  `}
`;

const ToOurProductsPage = styled.div`
  cursor: pointer;
`;
