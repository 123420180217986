import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';

const menuList = [
  {
    key: 'ourProducts',
    path: '/ourProducts',
    menuName: '音乐类应用',
    paths: ['/ourProducts', '/laiyinlianqinTeacher'],
  },
  {
    key: 'moreApplications',
    path: '/moreApplications',
    menuName: '工具类应用',
  },
  { key: 'aboutUs', path: '/aboutUs', menuName: '关于我们' },
  {
    key: 'socialResponsibility',
    path: '/socialResponsibility',
    menuName: '社会责任',
  },
  //   { key: 'moreApplications', path: '/moreApplications', menuName: '更多应用' },
];

export default function Menu() {
  const navigate = useNavigate();
  const location = useLocation();

  const currentRoutePath = _.get(location, 'pathname');

  const handleNavigate = (routeKey) => {
    navigate(`${routeKey}`);
  };

  return (
    <MenuWrapper>
      {_.map(menuList, ({ key, path, menuName, paths = [] }) => {
        const isActiveMenu =
          currentRoutePath === path || _.includes(paths, currentRoutePath);
        return (
          <MenuItem
            key={key}
            className={isActiveMenu ? 'active' : ''}
            onClick={() => handleNavigate(path)}
          >
            {menuName}
            <div className='colorBlock' />
          </MenuItem>
        );
      })}
    </MenuWrapper>
  );
}

const MenuWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  font-size: 16px;
`;

const MenuItem = styled.div`
  margin: 0 20px;
  position: relative;
  cursor: pointer;
  > .colorBlock {
    position: absolute;
    width: 40px;
    height: 4px;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: -8px;
  }
  &.active {
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ff950a;
    > .colorBlock {
      position: absolute;
      width: 40px;
      height: 4px;
      background: #ffab18;
      margin: 0 auto;
      left: 0;
      right: 0;
      bottom: -8px;
    }
  }
  &:hover {
    font-family: PingFangSC-Semibold, PingFang SC;
    /* font-weight: 600; */
    color: #ff950a;
  }
`;
