// @flow
import * as React from 'react';
import styled from 'styled-components';

function Icon({
    children,
    className,
    onClick,
    theme,
    color,
    size = 0,
    light = null,
    black = null,
    iconfont = false,
    ...rest
}) {
    const sizeFix = size ? `${size}px` : '24px';

    let fill = '#4E5C6E';
    if (color) fill = color;
    if (light) fill = '#FFF';
    if (black) fill = '#000';

    return (
        <Svg
            fill={fill}
            width={sizeFix}
            height={sizeFix}
            viewBox={iconfont ? '0 0 1024 1024' : '0 0 24 24'}
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            onClick={onClick}
            {...rest}
        >
            {children}
        </Svg>
    );
}

const Svg = styled.svg`
    ${(props) => !!props.clicker && 'cursor: pointer;'}

    ${(props) =>
        !!props.disabled &&
        `cursor: not-allowed; opacity: 0.5;
`}
`;

export default Icon;
