import * as React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import * as Icons from '../icons';

export default function ShowAllIcon() {
  const icons = _.entries(Icons);
  const RCE = React.createElement;

  return (
    <Wrapper>
      <div className='content'>
        {_.map(icons, (item) => {
          const icon = RCE(item[1], { size: 40, className: 'micon' }, null);
          const wrapper = RCE(
            'div',
            { key: item[0], className: 'icon-wrapper' },
            icon,
            RCE('p', {}, item[0])
          );
          return wrapper;
        })}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100vw;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    .icon-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      width: 150px;
      height: 120px;
      margin: 10px 15px;
      font-size: 14px;
      border: 1px solid #dfdfdf;
    }
  }
`;
