import React, { useEffect } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import useIsPhone from '../../hooks/useIsPhone';

import HeaderImg from '../../components/HeaderImg';
import BlockTitleImage from '../../components/BlockTitleImage';

import AppIntroBlock from './components/AppIntroBlock';
import BannerList from './components/BannerList';

import {
  ourProductsInfoList,
  otherProductsInfoList,
  otherProductsInfoListOfPhone,
} from './config';

export default function OurProducts() {
  const isPhone = useIsPhone();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  }, []);

  return (
    <Wrapper>
      <HeaderImg
        url={require(`./images/${isPhone ? 'phone' : 'pc'}_header.png`)}
      />
      <BlockTitleImage url={require('./images/ourproduct_img.png')} />
      {_.map(ourProductsInfoList, (item) => (
        <AppIntroBlock
          key={_.get(item, 'key')}
          projectBlockData={item}
          blockBgColor={_.get(item, 'key') % 2 === 0 ? '#ffffff' : '#FFFCF5'}
        />
      ))}
      <BlockTitleImage url={require('./images/otherproducts_img.png')} />
      <BannerList
        bannerListData={otherProductsInfoList}
        bannerListDataOfPhone={otherProductsInfoListOfPhone}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
