export const moreProductsInfoList = [
  {
    key: 0,
    appName: '时刻守护',
    modalTitleImg: 'shikeshouhu_modal_title_img',
    appImg: 'shikeshouhu_app_img',
    bgImg: 'shikeshouhu_bg_img',
    adImgKey: 'shikeshouhu_ad',
    iOSDownloadLink: 'https://apps.apple.com/cn/app/id1451287165',
    androidMarket: 'market://details?id=com.xihang.sksh',
    bgImgInfo: {
      width: 95,
      height: 95,
      bottom: 113,
      right: 220,
    },
    introduction: [
      '时刻守护是一款可以查看亲友实时定位和历史轨迹的App，如果你经常需要知道爱人、父母、孩子、好友都去过哪儿，时刻守护是你最好的帮手。',
    ],
    infos: [
      {
        title: '查询位置',
        info: '后台实时精准定位查看位置，还能查看好友的交通方式、手机电量，时刻 关注好友的动态。',
      },
      {
        title: '历史轨迹',
        info: '查看亲友30天内的轨迹，时刻掌握TA的行踪。',
      },
      {
        title: '地点出入提醒',
        info: '及时收到亲友到达或离开指定位置的通知。',
      },
      {
        title: '共享定位',
        info: '亲友到达或离开指定位置的通知。',
      },
      {
        title: '一键求助',
        info: '遇险一键求助 时刻守护TA的安全。',
      },
      {
        title: '安全工具',
        info: '伪装来电/语音等高级功能可以使用，时刻守护你和亲友的安全。',
      },
    ],
    infoTitleLength: 6,
  },
  {
    key: 1,
    appName: '灵敢足迹',
    modalTitleImg: 'lingganzuji_modal_title_img',
    appImg: 'lingganzuji_app_img',
    bgImg: 'lingganzuji_bg_img',
    adImgKey: 'lingganzuji_ad',
    iOSDownloadLink: 'https://apps.apple.com/cn/app/id1539411511',
    androidMarket: 'market://details?id=com.xihang.footprint',
    bgImgInfo: {
      width: 724,
      height: 356,
      bottom: 208,
      right: -50,
    },
    introduction: ['足迹是一款专注于记录轨迹的App，能帮助你记录每一天的足迹。'],
    infos: [
      {
        title: '超棒体验',
        info: '地图运行流畅，能在世界级地图展示百万级坐标。',
      },
      {
        title: '超高精度',
        info: '稳定、准确定位您的坐标，自动纠偏。',
      },
      {
        title: '超低耗电',
        info: '极低日常耗电，远低于同类型App。',
      },
      {
        title: '保护隐私',
        info: '无账号登录，无后端数据服务，保护数据隐私。',
      },
    ],
    infoTitleLength: 4,
  },
  {
    key: 2,
    appName: '灵敢提词器',
    modalTitleImg: 'lingganticiqi_modal_title_img',
    appImg: 'lingganticiqi_app_img',
    bgImg: 'lingganticiqi_bg_img',
    adImgKey: 'lingganticiqi_ad',
    iOSDownloadLink: 'https://apps.apple.com/cn/app/id1513916989',
    androidMarket: 'market://details?id=com.xihang.teleprompter',
    bgImgInfo: {
      width: 702,
      height: 525,
      bottom: 39,
      right: -47,
    },
    introduction: [
      '这是一款up主们都在用的vlog拍摄神器！只需将文稿粘贴到提词器App中，就能实现自动滚动播放，让你在讲话时保持从容淡定。',
    ],
    infos: [
      {
        title: '自动滚动提词',
        info: '自动调节台词滚动播放速度，台词跟着语速走，让你轻松录制短视频。',
      },
      {
        title: '悬浮窗提词',
        info: '提词板变悬浮窗可配合直播，短视频软件使用，自定义提词板大小透明度，支持横竖屏切换提词。',
      },
      {
        title: '蓝牙控制',
        info: '蓝牙连接控制器，让你在短视频拍摄提词过程中轻松控制台词播放与暂停。',
      },
      {
        title: '文本多设备同步',
        info: '不同移动设备登陆提词器，实时同步台词文本到另一台设备。',
      },
      {
        title: '功能丰富',
        info: '五线谱转简谱、内置节拍器、乐谱标注、弹奏录音、自动翻页、乐理知识等。',
      },
    ],
    infoTitleLength: 7,
  },
  {
    key: 3,
    appName: '恋爱ing',
    modalTitleImg: 'lianaiing_modal_title_img',
    appImg: 'lianaiing_app_img',
    bgImg: 'lianaiing_bg_img',
    adImgKey: 'lianaiing_ad',
    iOSDownloadLink: 'https://apps.apple.com/cn/app/id1442081643',
    androidMarket: 'market://details?id=com.qufaya.couple',
    bgImgInfo: {
      width: 750,
      height: 479,
      bottom: 8,
      right: -56,
    },
    introduction: [
      '恋爱ing致力于为情侣们打造专属的私密互动空间，在这里你们可以记录每一天的恋爱时光，同时还可以使用纪念日、写信、月经期、愿望清单等专属服务。',
    ],
    infos: [
      {
        title: '日记',
        info: '记录幸福的点滴，留住你我间的美好回忆，只有彼此间能看到哦。',
      },
      {
        title: '纪念日',
        info: '贴心的提醒功能，让你不错过每个值得纪念的日子。',
      },
      {
        title: '月经期',
        info: '准确预测经期，特殊的日子里给你不一样的关怀。',
      },
      {
        title: '恋爱清单',
        info: '情侣必做的100件事，要和ta一件件完成。',
      },
      {
        title: '装扮',
        info: '可自定义图标/启动图/背景图，快来装扮你们爱的空间吧。',
      },
    ],
    infoTitleLength: 4,
    subIntroduction: ['想知道你们的恋情有多甜蜜吗，来恋爱ing体验一下才知道！'],
  },
  {
    key: 4,
    appName: '灵敢文案',
    modalTitleImg: 'lingganwenan_modal_title_img',
    appImg: 'lingganwenan_app_img',
    bgImg: 'lingganwenan_bg_img',
    adImgKey: 'lingganwenan_ad',
    iOSDownloadLink: 'https://apps.apple.com/cn/app/id6447736926',
    androidMarket: 'market://details?id=com.xihang.copywriting',
    bgImgInfo: {
      width: 730,
      height: 582,
      bottom: -13,
      right: -53,
    },
    introduction: [
      '灵敢文案app是一款专业且高效的文案提取编辑工具，支持音视频链接、文件中文案的提取，帮助你快速完成文案的创作。',
    ],
    infos: [
      {
        title: '支持多种形式',
        info: '主流平台的音视频链接、Mp3/Mp4/Wav、Doc/Docx/Txt文档 等 。',
      },
      {
        title: '使用简单',
        info: '复制链接，一键完成提取。',
      },
      {
        title: '极速提取',
        info: '2小时长视频文字提取仅需1分钟，准确率极高。',
      },
      {
        title: '文案管家',
        info: '文案编辑自动保存，方便管理不丢失。',
      },
    ],
    infoTitleLength: 6,
  },
];
