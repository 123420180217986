import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import OurProducts from './pages/OurProducts';
import LaiyinlianqinTeacher from './pages/LaiyinlianqinTeacher';
import AboutUs from './pages/AboutUs';
import SocialResponsibility from './pages/SocialResponsibility';
import MoreApplications from './pages/MoreApplications';
import ShowAllIcon from './pages/ShowAllIcons';

export default function Routers() {
  return (
    <Routes>
      <Route path='*' element={<Navigate to='/ourProducts' />} />
      <Route path='/ourProducts' element={<OurProducts />} />
      <Route path='/laiyinlianqinTeacher' element={<LaiyinlianqinTeacher />} />
      <Route path='/aboutUs' element={<AboutUs />} />
      <Route path='/socialResponsibility' element={<SocialResponsibility />} />
      <Route path='/moreApplications' element={<MoreApplications />} />
      <Route path='/allIcons' element={<ShowAllIcon />} />
    </Routes>
  );
}
